body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent text selection on buttons and clickable elements */
button, a, .btn {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

/* Improve touch targets on mobile */
@media (max-width: 480px) {
  button, a, [role="button"], input[type="button"], input[type="submit"] {
    min-height: 44px;
    min-width: 44px;
  }
  
  /* Add appropriate spacing between touchable elements */
  li, nav a, button {
    margin: 8px 0;
  }
}

